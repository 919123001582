import React from "react"
import { Route, Routes } from "react-router-dom"
import { UserProvider } from "./context/UserContext"
import { FeedPage as Feed } from "./pages/Feed/Feed"
import { LoginPage as Login } from "./pages/Login/Login"
import { ProfilePage as Profile } from "./pages/Profile/Profile"
import { SignupPage as Signup } from "./pages/Signup/Signup"
import { UploadPage as Upload } from "./pages/Upload/Upload"
import { MarketplacePostPage as MarketplacePost } from "./pages/MarketplacePost/MarketplacePost"
import { TrendingPage as Trending } from "./pages/Trending/Trending"
import { SettingsPage as Settings } from "./pages/Settings/Settings"
import { CheckoutPage as Checkout } from "./pages/Checkout/Checkout"
import { ReturnPage as Return } from "./pages/CheckoutReturn/CheckoutReturnPage"
import { EditMemePage } from "./pages/EditMeme/EditMeme"
import { SkeletonTheme } from "react-loading-skeleton"

export const App: React.FC = () => {
  return (
    <SkeletonTheme baseColor="#dacce8">
      <UserProvider>
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Feed />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/trending" element={<Trending />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/gallery/:imageid" element={<MarketplacePost />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/return" element={<Return />} />
          <Route path="/gallery/:imageid/edit" element={<EditMemePage />} />
        </Routes>
      </UserProvider>
    </SkeletonTheme>
  )
}
