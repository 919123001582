import { InputHTMLAttributes } from "react"
import styles from "./Input.module.scss"

export const Input: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  placeholder,
  name,
  type,
  min,
  value,
  onChange,
}) => {
  return (
    <input
      className={styles.input}
      placeholder={placeholder}
      name={name}
      type={type}
      min={min}
      value={value}
      onChange={onChange}
    />
  )
}
