import cn from "classnames"

import { Meme } from "../../../types/meme"
import styles from "./MemeCard.module.scss"

import AvatarPlaceholder from "../../../assets/avatar_placeholder.png"
import { ButtonWithIcon } from "../ButtonWithIcon/ButtonWithIcon"
import { Rating } from "../Rating/Rating"
import { Link } from "react-router-dom"
import { useContext, useState } from "react"
import ShareModal from "../ShareModal/ShareModal"
import { Button } from "../Button/Button"
import { UserContext } from "../../../context/UserContext"

export const MemeCard: React.FC<{
  className?: string
  meme: Meme
}> = ({ className, meme }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user } = useContext(UserContext)

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const pressBuyMeme = () => {
    if (!user) {
      window.location.href = "/login"
    } else {
      const stripeProductId = meme.stripeProductId
      const memeImageId = meme.uid
      const memeOwnerId = meme.author.uid
      window.location.href = `/checkout?stripeProductId=${stripeProductId}&memeImageId=${memeImageId}&memeOwnerId=${memeOwnerId}`
    }
  }

  return (
    <>
      <div className={cn([className, styles.card])}>
        <div className={styles.card__header}>
          <div className={styles.userInfo}>
            <a href="/profile">
              <img
                className={styles.avatar}
                src={AvatarPlaceholder}
                alt="Avatar"
              />
              {/* Currently trims the email ('@' and after), should use username in future */}
              <h2 className={styles.username}>
                {meme.author.email.split("@")[0]}
              </h2>
            </a>
          </div>
          {meme.author.uid === user?.uid && (
            <div className={styles.edit_button}>
              <Link to={`/gallery/${meme.uid}/edit`}>
                <ButtonWithIcon icon="EditButton" />
              </Link>
            </div>
          )}
          <Button className={styles.followBtn} text="+follow"></Button>
        </div>

        <div className={styles.card__content}>
          <div className={styles.card__imageWrapper}>
            <Link to={`/gallery/${meme.uid}`}>
              <img className={styles.image} src={meme.image} alt={meme.title} />
            </Link>
          </div>
          <ul className={styles.controls}>
            <li
              className={styles.controls__item}
              style={{
                opacity: meme.price && meme.author.uid !== user?.uid ? 1 : 0.3,
              }}
              onClick={e => {
                if (meme.price === 0 || meme.author.uid === user?.uid) {
                  e.preventDefault()
                  return
                }
                pressBuyMeme()
              }}
            >
              <ButtonWithIcon type="primary" icon="Buy" />
            </li>
            <li className={styles.controls__item} style={{ opacity: 0.3 }}>
              <ButtonWithIcon icon="CommentBlack" />
            </li>
            <li className={styles.controls__item}>
              <ButtonWithIcon icon="SendBlack" onClick={openModal} />
            </li>
            <li className={styles.controls__item} style={{ opacity: 0.3 }}>
              <ButtonWithIcon icon="BookmarkBlack" />
            </li>
          </ul>
        </div>

        <div className={styles.rating__wrapper}>
          <Rating meme={meme} />
        </div>
      </div>
      <>
        {isModalOpen && (
          <ShareModal
            isOpen={isModalOpen}
            onClose={closeModal}
            title="Share"
            memeUid={meme.uid}
          />
        )}
      </>
    </>
  )
}
