import { EditMeme } from "../../components/common/EditMeme/EditMeme"
import { BaseLayout } from "../../components/layouts/BaseLayout/BaseLayout"

export const EditMemePage: React.FC = () => {
  return (
    <BaseLayout>
      <EditMeme />
    </BaseLayout>
  )
}
