import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import Skeleton from "react-loading-skeleton"
import { ColorRing } from "react-loader-spinner"
import { UserContext } from "../../../context/UserContext"
import styles from "./EditMeme.module.scss"
import { Button } from "../Button/Button"
import { Input } from "../../common/Input/Input"
import { firebase } from "../../../lib/firebase"
import SiteNavigationBar from "../SiteNavigationBar/SiteNavigationBar"
import { Meme } from "../../../types/meme"

export const EditMeme: React.FC = () => {
  const { user } = useContext(UserContext)
  const [image, setImage] = useState<string | undefined>(undefined)
  const [meme, setMeme] = useState<Meme>()
  const [onboardingStatus, setOnboardingStatus] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [price, setPrice] = useState<number | string>("")
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const apiUrl = process.env.REACT_APP_API_URL
  let { imageid } = useParams()

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = await firebase.getToken()

        if (token != null && user != null) {
          axios.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${token}`
            return config
          })

          const response = await axios.get(
            `${apiUrl!}/auth/user/${user?.uid}`,
            {
              params: {
                code: "k7LlVZlTELTJw40_SrFwMdCvd_iD7NLiW0uKc7iIkWCPAzFuv5Dy9g==",
              },
            },
          )
          const userData = response.data
          setOnboardingStatus(userData.onboardingStatus)
        }
      } catch (error) {
        console.error("Error fetching user data:", error)
      }
    }

    fetchUserData()
  }, [apiUrl, user])

  useEffect(() => {
    const fetchMeme = async () => {
      const token = await firebase.getToken()
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      try {
        const response = await axios.get(
          `${apiUrl}/image/${imageid}?code=6zMDh54WZB9lNsWb88msMAmT4dPfjpr0p1mcks9CcMYPAzFuNiPnrA==`,
          { headers },
        )
        setMeme(response.data)
      } catch (error) {
        console.error("Error fetching meme:", error)
      }
    }

    fetchMeme()
  }, [apiUrl, imageid])

  useEffect(() => {
    if (meme) {
      setImage(meme.image)
      setPrice(meme.price)
      setTitle(meme.title)
    }
  }, [meme])

  const handleSaveButtonClick = async () => {
    if (image) {
      try {
        setIsLoading(true)
        const priceValue = price ? price : 0

        const token = await firebase.getToken()
        const headers = {
          Authorization: `Bearer ${token}`,
        }

        if (price !== 0 && onboardingStatus !== "complete") {
          setIsLoading(false)
          console.log("Onboarding not completed, cannot put meme for sale.")
        } else {
          await axios.put(`${apiUrl}/image/${imageid}`, null, {
            headers,
            params: {
              code: "lVWQwLU-vbqTlhADC1Aw3IdGLGiTVftXg9pSOrwxrMlDAzFuFqET7A==",
              newTitle: title,
              newPrice: Number(priceValue),
            },
          })

          setIsLoading(false)
          setShowSuccessModal(true)
          setTimeout(() => {
            setShowSuccessModal(false)
          }, 2000)
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={styles.background}>
      <SiteNavigationBar text="Edit meme" />
      <div className={styles.header}>
        {image && (
          <Button
            text="Save"
            className={styles.saveButton}
            onClick={() => {
              handleSaveButtonClick()
            }}
          />
        )}
      </div>
      <form className={styles.edit}>
        <div>
          {image ? (
            <div>
              <img src={image} alt="Preview" className={styles.previewImage} />
            </div>
          ) : (
            <Skeleton height={400} width={420} />
          )}
          {showSuccessModal && (
            <div className={styles.successModal}>
              <h3>Save successful!</h3>
            </div>
          )}
        </div>
        <div className={styles.imageInfoForm}>
          <label>
            Title
            <Input
              type="text"
              name="title"
              placeholder="Meme's title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </label>
          <br />
          <label>
            Price (SEK)
            <Input
              type="number"
              name="price"
              placeholder="Price for sale"
              value={price}
              onChange={e => setPrice(e.target.value)}
              min="0"
            />
          </label>
          <label>
            <p>
              To be able to have a meme for sale, you need to complete the
              onboarding via Stripe (found in your{" "}
              <a href="/settings">settings page</a>).
            </p>
            {onboardingStatus && (
              <p>
                Your onboarding status is{" "}
                {onboardingStatus === "complete"
                  ? onboardingStatus
                  : "not complete"}
                .
              </p>
            )}
          </label>
        </div>
      </form>
      {isLoading && (
        <div className={styles.loader}>
          <ColorRing
            colors={["#FCFF71", "#DA72FF", "#66E4F5", "#DA72FF", "#FCFF71"]}
            height={70}
            width={80}
          />
        </div>
      )}
    </div>
  )
}
