import { Upload } from "../../components/common/Upload/Upload"
import { BaseLayout } from "../../components/layouts/BaseLayout/BaseLayout"
import { useTitle } from "../../hooks/useTitle"

export const UploadPage: React.FC = () => {
  useTitle("Upload")
  return (
    <BaseLayout>
      <Upload />
    </BaseLayout>
  )
}
