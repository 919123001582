import { SingleMeme } from "../../components/common/SingleMeme/SingleMeme"
import { BaseLayout } from "../../components/layouts/BaseLayout/BaseLayout"

export const MarketplacePostPage: React.FC = () => {
  return (
    <BaseLayout>
      <SingleMeme />
    </BaseLayout>
  )
}
