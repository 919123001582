import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { firebase } from "../../../lib/firebase"
import { Button } from "../../common/Button/Button"
import { Input } from "../../common/Input/Input"
import styles from "./Signup.module.scss"

export const SignupForm: React.FC = () => {
  const navigate = useNavigate()

  const submitHandler = async (event: React.BaseSyntheticEvent) => {
    event.preventDefault()

    const {
      email: emailInput,
      password: passwordInput,
      userName: userNameInput,
    } = event.target.elements
    const email = emailInput.value
    const password = passwordInput.value
    const userName = userNameInput.value

    try {
      await firebase.createUser({ email, password, userName })
      navigate("/")
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form className={styles.form} onSubmit={submitHandler}>
      <label className={styles.greeting}>Register</label>
      <label className={styles.field}>
        <Input placeholder="Username" type="text" name="userName" />
      </label>
      <label className={styles.field}>
        <Input placeholder="Email" type="text" name="email" />
      </label>
      <label className={styles.field}>
        <Input placeholder="Password" type="password" name="password" />
      </label>
      <div className={styles.buttonDiv}>
        <Button
          className={styles.button}
          text="Cancel"
          type="secondary"
          onClick={() => (window.location.href = "/")}
        />
        <Button
          className={styles.button}
          text="Register"
          type="primary"
          htmlType="submit"
        />
      </div>
      <p className={styles.note}>
        Already have an account? Please{" "}
        <Link className={styles.link} to="/login">
          log in
        </Link>
        .
      </p>
    </form>
  )
}
