import React from "react"
import cn from "classnames"

import HouseWhite from "../../../assets/icons/house_white.svg"
import HouseBlack from "../../../assets/icons/home.svg"
import GrowWhite from "../../../assets/icons/grow_white.svg"
import GrowBlack from "../../../assets/icons/trending.svg"
import SearchWhite from "../../../assets/icons/search_white.svg"
import SearchBlack from "../../../assets/icons/search.svg"
import BellWhite from "../../../assets/icons/bell_white.svg"
import BellBlack from "../../../assets/icons/notificationbell.svg"
import BookmarkWhite from "../../../assets/icons/bookmark_white.svg"
import BookmarkBlack from "../../../assets/icons/bookmark.svg"
import ProfileEditWhite from "../../../assets/icons/profile_edit_white.svg"
import ProfileEditBlack from "../../../assets/icons/user_profile.svg"
import GearWhite from "../../../assets/icons/gear_white.svg"
import GearBlack from "../../../assets/icons/settings_gear.svg"
import CommentWhite from "../../../assets/icons/comment_white.svg"
import CommentBlack from "../../../assets/icons/comment.svg"
import SendWhite from "../../../assets/icons/send_white.svg"
import SendBlack from "../../../assets/icons/send.svg"
import MarketPlace from "../../../assets/icons/marketplace.svg"
import Upload from "../../../assets/icons/upload.svg"
import Buy from "../../../assets/icons/buy.svg"
import EditButton from "../../../assets/icons/edit_button.png"

import styles from "./ButtonWithIcon.module.scss"

const icons: {
  [key: string]: string
} = {
  BellWhite,
  BellBlack,
  HouseWhite,
  HouseBlack,
  GrowWhite,
  GrowBlack,
  SearchWhite,
  SearchBlack,
  BookmarkWhite,
  BookmarkBlack,
  ProfileEditWhite,
  ProfileEditBlack,
  GearWhite,
  GearBlack,
  CommentWhite,
  CommentBlack,
  SendWhite,
  SendBlack,
  MarketPlace,
  Upload,
  Buy,
  EditButton,
}

type ButtonTypes = "primary" | "default"

export const ButtonWithIcon: React.FC<{
  icon: string
  type?: ButtonTypes
  onClick?: () => void
}> = ({ icon: iconName, type = "default", onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick() // Call the provided onClick function, if any
    }
  }

  return (
    <button
      className={cn([
        styles.button,
        { [styles.button_primary]: type === "primary" },
      ])}
      onClick={handleClick}
    >
      <img className={styles.button__img} src={icons[iconName]} alt="icon" />
    </button>
  )
}
